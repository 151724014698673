import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useApp } from 'contexts/AppContext';
import { useTheme } from 'contexts/ThemeContext';

import { adminRights as adminRightsConstant } from 'utils/constants';
import { activateAllRights } from 'utils/functions';

import { Modal } from 'components/Feedback';
import { FormSection } from 'components/FormSection';
import { Button, MultipleSelect, Select, TextField } from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';

import { flattenDataProviders } from '../../utils';

export const ModalSetup = props => {
  const {
    onValidate,
    onClose,
    actionName,
    dataProviders: dataProvidersOptions,
    presetList,
    adminRole,
    activableMedias
  } = props;

  const { themeColors } = useTheme();
  const { domainConfig } = useApp();
  const initialState = {
    domain: '',
    name: '',
    dataProviders: [],
    excludeDataProviders: [],
    presetId: '',
    email: '',
    firstName: '',
    lastName: '',
    productAccess: [
      { product: 'ermes', access: 'unlimited' },
      { product: 'ramses', access: 'blocked' }
    ],
    selectedActivableMedias: domainConfig.activableMedias
  };

  const ErrorState = {
    domainError: false,
    presetIdError: false,
    emailError: false,
    firstNameError: false,
    lastNameError: false,
    productAccessError: false
  };

  const [emailErrorMessage, setEmailErrorMessage] = useState('');

  const ramsesAccess = [
    {
      label: 'Accès interdit',
      value: 'blocked'
    },
    {
      label: 'Accès illimité',
      value: 'unlimited'
    }
  ];

  const ermesAccess = [
    ...ramsesAccess,
    {
      label: 'Abonné',
      value: 'subscriber'
    }
  ];

  const [
    {
      domain,
      name,
      dataProviders,
      excludeDataProviders,
      presetId,
      email,
      firstName,
      lastName,
      productAccess,
      selectedActivableMedias
    },
    setState
  ] = useState(initialState);

  const resetStates = () => {
    setState(initialState);
  };

  const handleChangeAccess = itemKey => value => {
    const newAccess = {
      product: itemKey,
      access: value
    };
    const index = productAccess.findIndex(el => el.product === itemKey);
    if (index === -1) {
      productAccess.push(newAccess);
    } else {
      productAccess[index] = newAccess;
    }
    setState(prevState => {
      const states = { ...prevState };
      states.productAccess = productAccess;
      return states;
    });
  };

  const [
    {
      domainError,
      presetIdError,
      emailError,
      firstNameError,
      lastNameError,
      productAccessError
    },
    setError
  ] = useState(ErrorState);

  const toggleError = (itemKey, state) => {
    setError(prevState => {
      const errors = { ...prevState };
      errors[`${itemKey}Error`] = state;
      return errors;
    });
  };

  const handleChange = itemKey => value => {
    const text = typeof value === 'string' ? value.trim() : value;
    toggleError(itemKey, !text);
    if (itemKey === 'domain') {
      const regex = /^[a-zA-Z0-9_]+$/;
      toggleError(itemKey, !regex.test(value));
    }
    setState(prevState => {
      const states = { ...prevState };
      states[itemKey] = text;
      return states;
    });
  };

  const doAction = async () => {
    const isEmptyDataProviders = dataProviders.length === 0;
    const isEmptyExcludeDataProviders = excludeDataProviders.length === 0;
    let error = false;
    if (domainError) {
      error = true;
    }
    const datas = {
      domain,
      name: name || domain,
      dataProviders: isEmptyDataProviders
        ? dataProviders
        : flattenDataProviders(dataProviders),
      excludeDataProviders: isEmptyExcludeDataProviders
        ? excludeDataProviders
        : flattenDataProviders(excludeDataProviders),
      presetId,
      activableMedias: selectedActivableMedias,
      groupTaxonomy: [
        {
          level: 'shop',
          name: 'Magasin',
          key: 'shop'
        }
      ],
      user: {
        email,
        firstName,
        lastName,
        productAccess,
        roles: [adminRole.id]
      },
      allRights: activateAllRights(adminRightsConstant)
    };
    setEmailErrorMessage('');
    if (!domain) {
      toggleError('domain', true);
      error = true;
    }
    if (!presetId) {
      toggleError('presetId', true);
      error = true;
    }
    if (!firstName) {
      toggleError('firstName', true);
      error = true;
    }
    if (!lastName) {
      toggleError('lastName', true);
      error = true;
    }
    if (!email) {
      toggleError('email', true);
      error = true;
    }
    if (productAccess.length !== 2) {
      toggleError('productAccess', true);
      error = true;
    }
    if (!error) {
      try {
        await onValidate(datas);
        resetStates();
      } catch (err) {
        setEmailErrorMessage(
          'Cette adresse email est déjà utilisée. Choisissez en une autre.'
        );
        toggleError('email', true);
      }
    }
  };

  const getActions = () => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs justify="flex-end">
            <Button
              variant="contained"
              color={themeColors.backoffice.global}
              size="medium"
              widthSize="medium"
              onClick={doAction}
            >
              {actionName} le domaine
            </Button>
          </Item>
        </Row>
      </Container>
    );
  };

  return (
    <Modal
      actions={getActions()}
      {...props}
      onClose={() => {
        resetStates();
        onClose();
      }}
    >
      <Container>
        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Nouveau domaine" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start" s>
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <TextField
                          title="Key du domaine"
                          required
                          placeholder="ERMES"
                          value={domain}
                          error={domainError}
                          onChange={handleChange('domain')}
                        />
                      </Item>
                    </Row>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <TextField
                          title="Nom du domaine"
                          placeholder="Ermes"
                          value={name}
                          onChange={handleChange('name')}
                        />
                      </Item>
                    </Row>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>

        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Sources de données" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <Container>
                          <Item justify="flex-start">
                            <MultipleSelect
                              title="Inclure"
                              small
                              placeHolder="Toutes les sources de données"
                              options={dataProvidersOptions}
                              selected={dataProviders.map(el => el.key)}
                              onChange={value => {
                                handleChange('dataProviders')(value);
                              }}
                            />
                          </Item>
                        </Container>
                      </Item>
                    </Row>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <Container>
                          <Item justify="flex-start">
                            <MultipleSelect
                              title="Exclure"
                              small
                              placeHolder="Aucune source de données"
                              options={dataProvidersOptions}
                              selected={excludeDataProviders.map(el => el.key)}
                              onChange={value => {
                                handleChange('excludeDataProviders')(value);
                              }}
                            />
                          </Item>
                        </Container>
                      </Item>
                    </Row>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>

        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Médias activables" xs={3}>
              <Row spacing={0} justify="flex-start">
                <Item xs={6} justify="flex-start">
                  <Item xs={11} justify="flex-start">
                    <MultipleSelect
                      small
                      required
                      selectAll
                      options={activableMedias.map(el => {
                        return { label: el.name, key: el.key };
                      })}
                      selected={selectedActivableMedias}
                      onChange={value => {
                        handleChange('selectedActivableMedias')(
                          value.map(el => el.key)
                        );
                      }}
                    />
                  </Item>
                </Item>
              </Row>
            </FormSection>
          </Item>
        </Row>

        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Preset" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <Container>
                          <Item justify="flex-start">
                            <Select
                              required
                              options={presetList.map(preset => ({
                                value: preset._id,
                                label: preset.name
                              }))}
                              title="Preset"
                              value={presetId}
                              error={presetIdError}
                              onChange={handleChange('presetId')}
                            />
                          </Item>
                        </Container>
                      </Item>
                    </Row>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>

        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Utilisateur" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <TextField
                          title="Prénom"
                          required
                          value={firstName}
                          error={firstNameError}
                          onChange={handleChange('firstName')}
                        />
                      </Item>
                    </Row>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <TextField
                          title="Nom"
                          required
                          value={lastName}
                          error={lastNameError}
                          onChange={handleChange('lastName')}
                        />
                      </Item>
                    </Row>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <TextField
                          title="Email"
                          required
                          value={email}
                          error={emailError}
                          errorText={emailErrorMessage}
                          onChange={handleChange('email')}
                        />
                      </Item>
                    </Row>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <TextField
                          title="Role"
                          value="ADMIN"
                          small
                          disabled
                          required
                        />
                      </Item>
                    </Row>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>

        <Row spacing={1}>
          <Item justify="flex-start">
            <FormSection title="Accès aux produits" xs={3}>
              <Container>
                <Row spacing={0} justify="flex-start">
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <Select
                          title="Ermes"
                          required
                          options={ermesAccess}
                          onChange={handleChangeAccess('ermes')}
                          value={
                            productAccess.find(el => el.product === 'ermes')
                              ?.access
                          }
                          error={productAccessError}
                        />
                      </Item>
                    </Row>
                  </Item>
                  <Item xs={6} justify="flex-start">
                    <Row spacing={0} justify="flex-start">
                      <Item xs={11} justify="flex-start">
                        <Select
                          title="Ramses"
                          required
                          options={ramsesAccess}
                          onChange={handleChangeAccess('ramses')}
                          value={
                            productAccess.find(el => el.product === 'ramses')
                              ?.access
                          }
                          error={productAccessError}
                        />
                      </Item>
                    </Row>
                  </Item>
                </Row>
              </Container>
            </FormSection>
          </Item>
        </Row>
      </Container>
    </Modal>
  );
};

ModalSetup.defaultProps = {
  onClose: () => null,
  actionName: '',
  presetList: [],
  adminRole: {}
};
ModalSetup.propTypes = {
  onValidate: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  actionName: PropTypes.string,
  presetList: PropTypes.arrayOf(PropTypes.any),
  adminRole: PropTypes.objectOf(PropTypes.any)
};

export default ModalSetup;
