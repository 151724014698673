import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CheckIcon from '@mui/icons-material/Check';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import Chip from '@mui/material/Chip';

import { useTheme } from 'contexts/ThemeContext';

import moment from 'utils/moment';

import { ButtonCreate } from 'components/ButtonCreate';
import { Text } from 'components/DataDisplay';
import { DatePicker } from 'components/DatePicker';
import {
  AutocompleteTextField,
  Button,
  IconButton,
  LocationSearchInput,
  Select,
  Switch,
  TextField
} from 'components/Inputs';
import { Container, Item, Row } from 'components/Layout';
import { Paper } from 'components/Surfaces';

export const ListField = props => {
  const {
    disabled,
    options,
    actionTxt,
    deleteAction,
    onChange,
    values,
    formatedValues,
    fieldsFormat,
    sortValues,
    searchOptionsKeys,
    textFieldOnTop,
    selectNbMax,
    deleteIcon,
    useWarning,
    deleteFunc,
    warningColor,
    placeholder,
    variant,
    inputs,
    isRequireds,
    tooltips,
    labels,
    units,
    bgColor,
    buttonBgColor,
    buttonTextColor,
    hideKeys,
    valueUnique,
    keyEnter,
    handleFavorite,
    favoriteValue
  } = props;
  const { themeColors } = useTheme();

  const [action, setAction] = useState(false);
  const [list, setlists] = useState(values);
  const [listUpdate, setListUpdate] = useState({});
  const [fields, setFields] = useState({});
  const [requireds] = useState(
    variant === 'autocomplete'
      ? isRequireds
      : inputs.map((_, index) =>
          typeof isRequireds[index] === 'boolean' ? isRequireds[index] : true
        )
  );
  const updateList = (datas, upsert = null) => {
    if (upsert) {
      onChange({ ...datas, tag: upsert });
      setlists({ ...datas, tag: upsert });
    } else {
      const sortedList = sortValues(datas);
      onChange(sortedList);
      setlists(sortedList);
    }
  };

  const onClick = () => {
    // init switch state
    if (variant === 'customFields') {
      const newState = inputs.reduce((acc, el, index) => {
        if (el === 'Switch') {
          acc[options[index]] = false;
        }
        return acc;
      }, {});

      setFields(newState);
    }
    setAction(true);
  };

  const onAutoCompliteChange = value => {
    const newList = list;
    newList.push(value);
    updateList(newList);
    setAction(false);
  };

  const onAddFields = () => {
    const newList = [...list];

    let block = false;
    const sortFields = options.reduce((acc, el, index) => {
      if (inputs[index] === 'TextFieldArray' && fields.tmp) {
        if (!fields[el]) {
          fields[el] = [];
        }
        fields[el].push(fields.tmp);
      }
      acc[el] = fields[el];
      if (requireds[index] && !acc[el]) {
        block = true;
      }
      return acc;
    }, {});
    if (!block) {
      newList.push(sortFields);
      setFields({});
      updateList(newList);
      setAction(false);
    }
  };

  const handleChange = (itemKey, isArray) => value => {
    if (!value) {
      return;
    }
    const text = typeof value === 'string' ? value.trim() : value;

    if (isArray) {
      setFields(prevState => {
        const states = { ...prevState };
        if (!states[itemKey]) {
          states[itemKey] = [];
        }
        delete states.tmp;
        states[itemKey].push(text);
        return states;
      });
      return;
    }

    setFields(prevState => {
      const states = { ...prevState };

      states[itemKey] = text;
      return states;
    });
  };

  const handleDelete = (itemKey, value) => () => {
    setFields(prevState => {
      const states = { ...prevState };
      states[itemKey] = states[itemKey].filter(el => el !== value);
      return states;
    });
  };

  const handleDeleteUpdate = item => (itemKey, value) => () => {
    // eslint-disable-next-line no-param-reassign
    item[itemKey] = item[itemKey].filter(el => el !== value);
    setListUpdate({ ...listUpdate });
  };

  const filterDelete =
    deleteFunc || ((data, allData) => allData.filter(item => item !== data));

  const onDelete = datas => {
    const newList = filterDelete(datas, list);
    updateList(newList);
  };

  const onUpdate = item => {
    listUpdate[JSON.stringify(item)] = JSON.parse(JSON.stringify(item)); // TODO A RENDRE MOINS MOCHE
    setListUpdate({ ...listUpdate });
  };

  const handleChangeUpdate = item => (itemKey, isArray) => value => {
    const text = typeof value === 'string' ? value.trim() : value;
    if (isArray) {
      if (!item[itemKey]) {
        // eslint-disable-next-line no-param-reassign
        item[itemKey] = [];
      }
      if (text) {
        item[itemKey].push(text);
        // eslint-disable-next-line no-param-reassign
        delete item.tmp;
        setListUpdate({ ...listUpdate });
      }
      return;
    }
    // eslint-disable-next-line no-param-reassign
    item[itemKey] = text;
    setListUpdate({ ...listUpdate });
  };

  const onUpdateField = keyItem => () => {
    let block = false;
    const item = listUpdate[keyItem];
    options.forEach((el, index) => {
      if (inputs[index] === 'TextFieldArray' && item.tmp) {
        if (!item[el]) {
          item[el] = [];
        }
        item[el].push(item.tmp);
        delete item.tmp;
      }
      if (requireds[index] && (!item[el] || item[el].length === 0)) {
        block = true;
      }
    });

    if (!block) {
      const index = list.findIndex(el => keyItem === JSON.stringify(el));
      list[index] = { ...listUpdate[keyItem] };
      delete listUpdate[keyItem];
      updateList([...list]);
    }
  };

  const handleTmpChange = item => value => {
    if (typeof value === 'undefined') {
      return;
    }
    const text = value.trim();
    // eslint-disable-next-line no-param-reassign
    item.tmp = text;
  };

  const getValueUnique = ({
    value,
    fieldsFormat: fieldsFormats,
    optionIndex
  }) => {
    return fieldsFormats.filter(fieldFormat => {
      const find = list.find(el => el[optionIndex] === fieldFormat.value);
      if (!find || find[optionIndex] === value) {
        return true;
      }
      return false;
    });
  };

  const showAction = keyEnter
    ? true
    : !selectNbMax || list.length < selectNbMax;

  const getRowList = (rowItem = [], value) => {
    return (
      <Row spacing={2}>
        <Item>
          <Paper style={{ flex: 1 }}>
            <Container>
              <Row spacing={0}>
                {Object.keys(rowItem).reduce((acc, key, index) => {
                  if (hideKeys.includes(key)) {
                    return acc;
                  }
                  const item = formatedValues?.[index] || list[index];
                  let el =
                    inputs &&
                    inputs[index] === 'Select' &&
                    Array.isArray(fieldsFormat[index])
                      ? fieldsFormat[index].find(
                          el => el.value === rowItem[key]
                        )?.label || rowItem[key]
                      : rowItem[key];
                  if (Array.isArray(item)) {
                    el =
                      item?.find(formatedValue => formatedValue.value === el)
                        ?.label || rowItem[key];
                  }
                  acc.push(
                    el !== rowItem?.warning && (
                      <Item xs justify="flex-start" style={{ padding: '5px' }}>
                        {typeof el === 'boolean' ? (
                          fieldsFormat[
                            options.findIndex(
                              option => option === Object.keys(rowItem)[index]
                            )
                          ](el)
                        ) : (
                          <Text bold={index === 0}>
                            {Array.isArray(el)
                              ? el.join(',')
                              : el?.[key] || el?.label || el}
                          </Text>
                        )}
                      </Item>
                    )
                  );
                  return acc;
                }, [])}
                {variant !== 'autocomplete' && (
                  <IconButton
                    onClick={() => {
                      onUpdate(value);
                    }}
                  >
                    <CreateIcon style={{ color: themeColors.light }} />
                  </IconButton>
                )}
                {handleFavorite && value._id !== favoriteValue && (
                  <Item xs={4}>
                    <Button
                      variant="text"
                      onClick={() => {
                        handleFavorite(value._id);
                      }}
                    >
                      Définir comme favorite
                    </Button>
                  </Item>
                )}
                {handleFavorite && value._id === favoriteValue && (
                  <Item xs={4}>
                    <Text color={themeColors.global} fontWeight={700}>
                      Favorite
                    </Text>
                  </Item>
                )}
                {deleteAction && (
                  <IconButton
                    disabled={disabled}
                    onClick={() => {
                      onDelete(value);
                    }}
                  >
                    {deleteIcon || (
                      <DeleteIcon style={{ color: themeColors.light }} />
                    )}
                  </IconButton>
                )}
              </Row>
            </Container>
          </Paper>
        </Item>
        {useWarning && rowItem.warning && (
          <Item>
            <Text bold color={warningColor}>
              Attention : &nbsp;
              <Text display="inline" color="inherit">
                {rowItem.warning}
              </Text>
            </Text>
          </Item>
        )}
      </Row>
    );
  };

  const getField = (onHandleChange, onAdd, onDeleteArray, item) => {
    return (
      <Container>
        <Row spacing={1}>
          <Item xs={11} justify="flex-start">
            <Container>
              <Row spacing={3} alignItems="flex-start" justify="flex-start">
                {options.map((el, index) => (
                  <Item
                    xs={options.length > 4 ? 4 : 12 / options.length}
                    justify="flex-start"
                    alignItems="flex-start"
                    style={{ paddingTop: 0 }}
                  >
                    {/* <Text noWrap>{labels?.[index] || el}</Text> */}
                    {inputs[index] === 'TextField' && (
                      <TextField
                        small
                        title={labels?.[index] || el}
                        required={requireds[index]}
                        onChange={onHandleChange(el)}
                        format={fieldsFormat && fieldsFormat[index]}
                        unit={units?.[index]}
                        bgColor={bgColor}
                        onEnter={inputs.length === 1 ? onAdd : undefined}
                        value={(item && item[el]) || ''}
                        tooltip={tooltips[index]}
                      />
                    )}
                    {inputs[index] === 'TextFieldArray' && (
                      <Item>
                        <Row spacing={0}>
                          <Item>
                            <TextField
                              small
                              title={labels?.[index] || el}
                              required={requireds[index]}
                              format={fieldsFormat && fieldsFormat[index]}
                              unit={units?.[index]}
                              bgColor={bgColor}
                              value={fields?.tmp}
                              onChange={handleTmpChange(item)}
                              onEnter={onHandleChange(el, true)}
                              tooltip={tooltips[index]}
                              cleanOnEnter
                            />
                          </Item>
                          <Item>
                            {item[el] &&
                              Array.isArray(item[el]) &&
                              item[el].map(value => (
                                <Chip
                                  variant="outlined"
                                  label={value}
                                  onDelete={onDeleteArray(el, value)}
                                  color="primary"
                                />
                              ))}
                          </Item>
                        </Row>
                      </Item>
                    )}
                    {inputs[index] === 'LocationSearchInput' && (
                      <LocationSearchInput
                        title={labels?.[index] || el}
                        color={themeColors.global}
                        adr={item?.[el]?.address ?? ''}
                        required={requireds[index]}
                        onPlaceSelected={({ lat, lng, address }) => {
                          onHandleChange(el)({ lat, lng, address });
                        }}
                      />
                    )}
                    {inputs[index] === 'Select' && (
                      <Item justify="flex-start">
                        <Select
                          small
                          title={labels?.[index] || el}
                          required={requireds[index]}
                          tooltip={tooltips[index]}
                          onChange={onHandleChange(el)}
                          options={
                            valueUnique
                              ? getValueUnique({
                                  value: item[el]?.value || item[el],
                                  fieldsFormat: fieldsFormat[index],
                                  optionIndex: options[index] || 'value'
                                })
                              : fieldsFormat[index]
                          }
                          value={item[el]?.label || item[el]?.value || item[el]}
                          bgColor={bgColor}
                        />
                      </Item>
                    )}
                    {inputs[index] === 'Switch' && (
                      <Item justify="flex-start">
                        <Switch
                          small
                          title={labels?.[index] || el}
                          required={requireds[index]}
                          onChange={() => onHandleChange(el)(!fields[el])}
                          checked={item[el]}
                        />
                      </Item>
                    )}
                    {inputs[index] === 'DatePicker' && (
                      <Item justify="flex-start">
                        <DatePicker
                          bgColor={bgColor}
                          title={labels?.[index] || el}
                          required={requireds[index]}
                          onClose={({ date }) =>
                            onHandleChange(el)(date?.format('YYYY-MM-DD') || '')
                          }
                          initialDate={
                            moment(item[el], 'YYYY-MM-DD').isValid()
                              ? moment(item[el], 'YYYY-MM-DD')
                              : undefined
                          }
                        />
                      </Item>
                    )}
                  </Item>
                ))}
              </Row>
            </Container>
          </Item>
          <Item xs={1}>
            <Container>
              <Row spacing={3}>
                <Item>
                  <Button onClick={onAdd}>
                    <CheckIcon style={{ color: themeColors.light }} />
                  </Button>
                </Item>
              </Row>
            </Container>
          </Item>
        </Row>
      </Container>
    );
  };

  const getRowUpdate = (item, keyItem) => {
    return getField(
      handleChangeUpdate(item),
      onUpdateField(keyItem),
      handleDeleteUpdate(item),
      item
    );
  };

  const getActionRow = () => {
    return (
      <Row>
        <Item justify="flex-start" xs={variant === 'customFields' ? 12 : 5}>
          {actionTxt &&
            !action &&
            (variant !== 'autocomplete' || keyEnter
              ? true
              : list.length !== options.length) && (
              <ButtonCreate
                disabled={disabled}
                onClick={onClick}
                justify="flex-start"
                buttonColor={buttonBgColor}
                textColor={buttonTextColor}
              >
                {actionTxt}
              </ButtonCreate>
            )}
          {(!actionTxt || action) && variant === 'autocomplete' && (
            <AutocompleteTextField
              options={options}
              formatLabel={option => {
                let str = option[searchOptionsKeys[0]];
                if (searchOptionsKeys[1]) {
                  str += ` (${option[searchOptionsKeys[1]]})`;
                }
                return str;
              }}
              exclude={option =>
                list.find(
                  el =>
                    el[searchOptionsKeys[0]] === option[searchOptionsKeys[0]]
                )
              }
              small
              bgColor={bgColor}
              onChange={onAutoCompliteChange}
              placeholder={placeholder}
              functionKeyEnter={newData => {
                if (newData) {
                  onAutoCompliteChange({ tag: newData });
                }
              }}
              keyEnter={keyEnter}
            />
          )}

          {(!actionTxt || action) &&
            variant === 'customFields' &&
            getField(handleChange, onAddFields, handleDelete, fields)}
        </Item>
        <Item xs />
      </Row>
    );
  };

  const items = list;
  return (
    <Container>
      {showAction && textFieldOnTop && getActionRow()}
      {items.map((item, i) => {
        const value = typeof item === 'string' ? { item } : item;
        try {
          const keyItem = JSON.stringify(value);
          if (listUpdate[keyItem]) {
            return getRowUpdate(listUpdate[keyItem], keyItem);
          }
        } catch (e) {
          console.error(e);
        }
        return getRowList(value, list[i]);
      })}
      {showAction && !textFieldOnTop && getActionRow()}
    </Container>
  );
};

ListField.defaultProps = {
  deleteAction: false,
  buttonBgColor: null,
  buttonTextColor: null,
  textFieldOnTop: false,
  useWarning: false,
  actionTxt: null,
  warningColor: null,
  formatedValues: null,
  tooltips: [],
  inputs: null,
  isRequireds: [true],
  labels: null,
  units: null,
  selectNbMax: null,
  deleteIcon: null,
  placeholder: null,
  fieldsFormat: null,
  deleteFunc: undefined,
  variant: 'autocomplete',
  sortValues: list => list,
  bgColor: null,
  hideKeys: [],
  valueUnique: false,
  keyEnter: false
};
ListField.propTypes = {
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  labels: PropTypes.arrayOf(PropTypes.any),
  buttonBgColor: PropTypes.string,
  buttonTextColor: PropTypes.string,
  units: PropTypes.arrayOf(PropTypes.any),
  searchOptionsKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  values: PropTypes.arrayOf(PropTypes.any).isRequired,
  formatedValues: PropTypes.arrayOf(PropTypes.any),
  fieldsFormat: PropTypes.arrayOf(PropTypes.string),
  tooltips: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  actionTxt: PropTypes.string,
  deleteAction: PropTypes.bool,
  sortValues: PropTypes.func,
  textFieldOnTop: PropTypes.bool,
  useWarning: PropTypes.bool,
  warningColor: PropTypes.string,
  selectNbMax: PropTypes.number,
  deleteIcon: PropTypes.node,
  deleteFunc: PropTypes.func,
  placeholder: PropTypes.string,
  bgColor: PropTypes.string,
  variant: PropTypes.string,
  inputs: PropTypes.arrayOf(PropTypes.string),
  hideKeys: PropTypes.arrayOf(PropTypes.string),
  isRequireds: PropTypes.arrayOf(PropTypes.bool),
  valueUnique: PropTypes.bool,
  keyEnter: PropTypes.bool
};

export default ListField;
